import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({
  currentPage,
  totalItems,
  handlePageChange,
  itemsPerPage
}) => {
  const totalPages = Math.ceil(totalItems.length/itemsPerPage);
  
  const pagesToShow = 5; // Number of pages to show at a time
  const halfPagesToShow = Math.floor(pagesToShow / 2);

  // Calculate the range of page numbers to display
  let startPage = Math.max(currentPage - halfPagesToShow, 1);
  let endPage = Math.min(currentPage + halfPagesToShow, totalPages);

  // Adjust the startPage and endPage if they go out of bounds
  if (totalPages <= pagesToShow) {
    // If total pages are less than or equal to 5, show all pages
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= halfPagesToShow) {
    endPage = pagesToShow;
  } else if (currentPage >= totalPages - halfPagesToShow) {
    startPage = totalPages - pagesToShow + 1;
  }

  // Add ... after the last page if there are more pages to show
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  const shouldShowEllipsis = totalPages > endPage;

  return (
    <div className="pagination mt-3" style={{ justifyContent: 'center' }}>
      <Pagination>
        <Pagination.First
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {pageNumbers.map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        ))}
        {shouldShowEllipsis && <Pagination.Ellipsis />}
        {shouldShowEllipsis && (
          <Pagination.Item onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </Pagination.Item>
        )}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
