import { React, useEffect, useState } from "react";
import CartProduct from "../Cart-Product/Cart-Product";
import "./Cart.css";
import axios from "axios";
import { useAlert } from "react-alert";
import { useHireContext } from "../../App.js";

function Cart(params) {
  const [darkMode, setDarkMode] = useState(params.Mode);
  const [Bill, setBill] = useState(params.total);
  const [CurrBill, setCurrBill] = useState();
  const [BillInEur, setBillInEur] = useState(params.total);
  const [Code, setCode] = useState(params.code);
  const [price, setPrice] = useState(params.price);
  const [TotalItems, setTotalItems] = useState(params.cart);
  const [Balance, setBalance] = useState(0);
  const [Fee, setFee] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState("");
  const [fixedDiscount, setFixedDiscount] = useState(0);
  const [discountType, setDiscountType] = useState("");
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [email, setEmail] = useState("");

  const alert = useAlert();
  const { currentCurrency, setCurrentCurrency } = useHireContext();

  useEffect(() => {
    setDarkMode(params.Mode);
    // setBill(params.total);
    setPrice(params.price);
    setCode(params.code);
    var temp = params.totalincurr;
    var f = temp * 0.02;
    var total = Math.round((f + Number.EPSILON) * 100) / 100;
    setBill(temp);
    //setFee(total);
    setFee(0);
    setBillInEur(params.total);
  });

  useEffect(() => {
    setTotalItems(params.cart);
    setEmail(sessionStorage.getItem("email"));
  }, [params]);

  useEffect(() => {
    setAmount("");
    setFixedDiscount(0);
    setDiscount(0);
  }, [params.totalincurr]);

  function handleItems(items) {
    setTotalItems(items);
  }
  function handleDiscount() {
    const promo = document.getElementById("promocode").value;
    if (promo !== "") {
      const data = {
        code: promo,
        total: params.totalincurr,
        currency: currentCurrency,
      };
      axios
        .post("https://ozchest.com/getpromo", data)
        .then((data1) => {
          if (data1) {
            const discount = data1.data.discount;
            let fee =
              Math.round((params.totalincurr * 0.02 + Number.EPSILON) * 100) /
              100;
            fee = 0;
            let discount2 = (params.othertotalincurr + fee) * (discount / 100);
            console.log(discount);
            if (params.othertotalincurr + fee - discount2 <= 0) {
              setDiscount(0);
              alert.error(
                "Current cart does not meet minumum requirement for discount"
              );
            } else {
              if (currentCurrency === "USD") {
                setFixedDiscount(data1.data.rate);
              }
              setDiscountType(data1.data.type);
              setDiscount(discount / 100);
            }
          }
        })
        .catch((error) => {
          alert.error("Invalid Promo Code");
        });
    } else {
      alert.error("Please Enter Promo Code");
    }
  }
  async function handleCheckout() {
    if (TotalItems?.length === 0) {
      alert.error("Cart is empty");
      return;
    }

    setCheckoutLoading(true);
    const data1 = {
      user: sessionStorage.getItem("email"),
    };
    var count = 0;
    var data2 = {
      from: currentCurrency,
      to: "USD",
      amount: params.totalincurr,
      value: 6,
    };
    const loggedInEmail = sessionStorage.getItem("email");

    if (!loggedInEmail) {
      setCheckoutLoading(false);
      alert.error("Kindly login/register to use checkout");
      return;
    }
    const res33 = await axios
      .post("https://ozchest.com/convert ", data2)
      .then(async (response22) => {
        let totalineur = response22.data.cur;
        let fee = Math.round((totalineur * 0.02 + Number.EPSILON) * 100) / 100;
        fee = 0;
        let discount = (totalineur + fee) * Discount;
        const res = await axios
          .post("https://ozchest.com/balance", data1)
          .then(async (data1) => {
            const balance = data1.data.balance;
            if (Bill !== 0 && balance >= totalineur + fee - discount) {
              count = count + 1;

              var data2;
              fee =
                Math.round(
                  (params.othertotalincurr * 0.02 + Number.EPSILON) * 100
                ) / 100;
              fee = 0;
              discount = (params.othertotalincurr + fee) * Discount;
              if (currentCurrency !== undefined) {
                data2 = {
                  from: currentCurrency,
                  to: "USD",
                  amount: params.othertotalincurr + fee - discount,
                  value: 6,
                };
              } else {
                data2 = {
                  from: "USD",
                  to: "USD",
                  amount: params.othertotalincurr + fee - discount,
                  value: 6,
                };
              }
              const res3 = await axios
                .post("https://ozchest.com/convert ", data2)
                .then(async (response2) => {
                  const products = TotalItems.reduce((result, item) => {
                    const existingItem = result.find(
                      (newItem) =>
                        newItem.id === item.id &&
                        newItem.faceValue === item.faceValue
                    );

                    if (existingItem) {
                      // If the item already exists, update its amount
                      existingItem.amount += item.amount;
                    } else {
                      // If the item doesn't exist, add a new object to the result array
                      if (item.brand !== "BitJem") {
                        result.push({
                          ProductId: item.id,
                          Quantity: item.amount,
                          Value: Number(item.faceValue),
                        });
                      }
                    }
                    return result;
                  }, []);
                  const bitjemProducts = TotalItems.filter(
                    (product) => product.brand === "BitJem"
                  );

                  const data = {
                    products: products,
                    user: sessionStorage.getItem("email"),
                    balance: balance,
                    total: totalineur - discount,
                    email: sessionStorage.getItem("email"),
                    bitjemProducts: bitjemProducts,
                    Discount: Discount,
                    value: 7,
                  };
                  const res2 = await axios
                    .post("https://ozchest.com/order", data)
                    .then((response) => {
                      if (response) {
                        sessionStorage.setItem("balance", balance - totalineur);
                        localStorage.setItem("items", JSON.stringify([]));
                        localStorage.setItem("cart", JSON.stringify([]));
                        params.setcart([]);
                        setTotalItems([]);
                        alert.success(
                          "Thanks for buying, kindly check your registered email inbox for purchased item"
                        );
                      }
                      setCheckoutLoading(false);
                    })
                    .catch((error) => {
                      alert.error("Something went wrong");
                      setCheckoutLoading(false);
                    });
                });
            } else {
              setCheckoutLoading(false);
              alert.error("Not enough balance");
            }
          })
          .catch((error) => {
            alert.error("Something went wrong");
            setCheckoutLoading(false);
          });

        setCheckoutLoading(false);
      })
      .catch((error) => {
        alert.error("Something went wrong");
        setCheckoutLoading(false);
      });
  }

  function closeCartScreen() {
    document
      .getElementById("cart")
      .style.setProperty("width", "0%", "important");
    document
      .getElementById("item-screen")
      .style.setProperty("width", "0%", "important");
    document.getElementById("dispatch").style.setProperty("display", "none");
    document.getElementById("cart").classList.remove("cart-size");
    setAmount("");
    setFixedDiscount(0);
  }

  function updateBill(bill) {
    setLoading(true);
    var data2;
    const cur = currentCurrency;
    if (bill !== 0) {
      if (cur !== undefined) {
        data2 = {
          from: "USD",
          to: cur,
          amount: bill,
          value: 6,
        };
      } else {
        data2 = {
          from: "USD",
          to: "USD",
          amount: bill,
          value: 6,
        };
      }
      axios.post("https://ozchest.com/convert ", data2).then((response2) => {
        setBill(response2.data.cur);
        setCurrBill(cur);
        setLoading(false);
      });
    } else {
      setBill(0);
      setCurrBill(cur);
      setLoading(false);
    }
  }
  function handleAmount(e) {
    if (e.target.value?.trim() === "" && Discount !== 0) {
      setDiscount(0);
      setFixedDiscount(0);
    }
    setAmount(e.target.value);
  }
  return (
    <div
      className={
        darkMode ? "cart-container dark-cart-container" : "cart-container"
      }
      id="cart"
    >
      <div
        className={
          darkMode
            ? "backbtn-box dark-backbtn-box mt-3 mb-3 p-2 ml-2"
            : "backbtn-box mt-3 mb-3 p-2 ml-2"
        }
      >
        <button onClick={closeCartScreen}>Back</button>
      </div>
      {checkoutLoading ? (
        <div className="cart-spinner-container">
          <div className="spinner-border cstm-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="cartdetails-box p-3">
          <div className="cart-quantity mb-3 ml-2">
            <h5>
              Your cart has <span>{TotalItems ? TotalItems.length : 0} </span>{" "}
              items{" "}
            </h5>
          </div>
          <div className="order-container">
            <div className="cart-items">
              <CartProduct
                Mode={darkMode}
                cart={params.cart}
                setcart={params.setcart}
                setBill={updateBill}
                setItems={handleItems}
                total={params.total}
                items={params.items}
                code={Code}
                amount={params.amount}
                cuurent={currentCurrency}
              ></CartProduct>
            </div>
            <div className="order-details">
              <div
                className={
                  darkMode
                    ? "order-summary dark-order-summary p-3"
                    : "order-summary p-3"
                }
              >
                <div className="summary-title">
                  <h4>Order Summary</h4>
                </div>
                <div className="summary-details">
                  <h5>Total: </h5>
                  <h5>
                    {currentCurrency ? currentCurrency : "USD"}{" "}
                    <span>{params.totalincurr}</span>
                  </h5>
                </div>
                <div className="summary-details">
                  <h5>Fees: </h5>
                  <h5>
                    {currentCurrency ? currentCurrency : "USD"}{" "}
                    <span>{Fee}</span>
                  </h5>
                </div>
                <div className="summary-details">
                  <h5>Discount: </h5>
                  <h5>
                    {currentCurrency === "USD" &&
                    discountType === "Fixed Cost" ? (
                      <span>-{fixedDiscount}</span>
                    ) : (
                      <span>
                        -
                        {Math.round(
                          (Discount * (params.totalincurr + Fee) +
                            Number.EPSILON) *
                            100
                        ) / 100}
                      </span>
                    )}
                  </h5>
                </div>
                <div className="summary-details">
                  <h4>Payable: </h4>
                  <h4>
                    {currentCurrency ? currentCurrency : "USD"}{" "}
                    {loading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : currentCurrency === "USD" &&
                      discountType === "Fixed Cost" ? (
                      <span>
                        {Math.round(
                          (params.totalincurr +
                            Fee -
                            fixedDiscount +
                            Number.EPSILON) *
                            100
                        ) / 100}
                      </span>
                    ) : (
                      <span>
                        {Math.round(
                          (params.totalincurr +
                            Fee -
                            Discount * (params.totalincurr + Fee) +
                            Number.EPSILON) *
                            100
                        ) / 100}
                      </span>
                    )}
                  </h4>
                </div>
              </div>
              <div
                className={
                  darkMode
                    ? "order-information dark-order-information mt-3 p-3"
                    : "order-information p-3 mt-3"
                }
              >
                <div className="order-options">
                  <h4>Order Information</h4>
                </div>

                <div className="order-info1 mt-3">
                  <h5>Email to receive purchase</h5>
                  <h5 style={{ fontWeight: "bold" }}>{email}</h5>
                </div>
                <div className="order-info2 mt-3">
                  <h5>Promo Code</h5>
                  <div className="promo-div">
                    <input
                      className="p-2"
                      placeholder="Promo Code"
                      id="promocode"
                      value={amount}
                      onChange={handleAmount}
                    ></input>
                    <button className="promo-btn p-2" onClick={handleDiscount}>
                      Apply
                    </button>
                  </div>
                </div>
                <div className="order-name mt-3">
                  <button
                    className="checkout-btn p-2"
                    onClick={() => handleCheckout()}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cart;
