import React, { useEffect, useState } from "react";
import "./Product.css";
import axios from "axios";
import { useAlert } from "react-alert";
import parse from "html-react-parser";
import altImg from "../../Assets/alt.jpg";
import cryptoCard from "../../Assets/cryptocard.jpg";

function ItemScreen(params) {
  const [darkMode, setDarkMode] = useState(params.Mode);
  const [Card, setCard] = useState(params.Item);
  const [Countries, setCountries] = useState(params.country);
  const [Curr, setCurr] = useState(params.curr ? params.curr : {});
  const [Stock, setStock] = useState({});
  const [price, setPrice] = useState(params.usd);
  const [selectedProduct, setselectedProduct] = useState({});
  const [fee, setFee] = useState(0);
  const [priceInCurrent, setPriceInCurrent] = useState(0);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(1);
  const [faceValue, setFaceValue] = useState(0);
  const [selectedCrypto, setSelectedCrypto] = useState("BTC");
  const [isBitJem, setIsBitJem] = useState(false);
  const [isBitJemLoading, setIsBitJemLoading] = useState(false);
  const alert = useAlert();
  const CryptoCurrencies = ["BTC", "USDT"];

  const handleCryptoChange = (event) => {
    setSelectedCrypto(event.target.value);
  };

  useEffect(() => {
    if (params.curr && params.curr[0]) {
      setLoading(true);
      var data = {
        productId: params.curr[0].id,
      };

      axios.post("https://ozchest.com/getStock", data).then((product) => {
        let count = product.data.count === null ? 1000 : product.data.count;
        const stock = {
          quantity: count,
        };
        let price = product.data.price.min;
        let fee = price * 0.02;
        fee = 0;
        var data2 = {
          from: params.curr[0].price.currencyCode,
          to: params.current ? params.current : "USD",
          amount: price + fee,
          value: 6,
        };

        axios.post("https://ozchest.com/convert ", data2).then((response2) => {
          setStock(stock);
          setFee(fee);
          setPrice(price);
          setselectedProduct(product.data);
          setPriceInCurrent(response2.data.cur);
          setFaceValue(product.data.minFaceValue);
          setLoading(false);
        });
      });
    }
  }, [params.curr, params.country]);

  useEffect(() => {
    if (params?.Item?.name === "BitJem") {
      setIsBitJem(true);
      setLoading(false);
    } else {
      setIsBitJem(false);
    }
    setDarkMode(params.Mode);
    setCountries(params.country);
    setCurr(params.curr);
  });

  function handleAmount() {
    var temp =
      selectedProduct.minFaceValue *
      Number(document.getElementById("amount1").value);
    var f = temp * 0.02;
    f = 0;
    setFee(f);
    setPrice(temp);
    setAmount(Number(document.getElementById("amount1").value));
  }
  useEffect(() => {
    setCard(params.Item);
  }, [params.Item]);

  function handleChange() {
    params.updateprice(Card.name);
    // params.updatestock(Card.Brand, Curr.code, 2);
  }

  function handleChange1(index) {
    setLoading(true);
    var data = {
      productId: params.curr[index].id,
    };

    axios.post("https://ozchest.com/getStock", data).then((product) => {
      let quantity = product.data.count === null ? 1000 : product.data.count;
      const stock = {
        quantity: quantity,
      };
      let price = product.data.price.min;
      let fee = price * 0.02;
      fee = 0;
      var data2 = {
        from: params.curr[0].price.currencyCode,
        to: params.current ? params.current : "USD",
        amount: price + fee,
        value: 6,
      };

      axios.post("https://ozchest.com/convert ", data2).then((response2) => {
        setStock(stock);
        setFee(fee);
        setPrice(price);
        setselectedProduct(product.data);
        setPriceInCurrent(response2.data.cur);
        setLoading(false);
      });
    });
  }

  function openCartScreen() {
    document.getElementById("cart").style.setProperty("width", "60%");
    document.getElementById("cart").classList.add("cart-size");
  }

  function closeItemScreen() {
    document
      .getElementById("item-screen")
      .style.setProperty("width", "0%", "important");
    document.getElementById("grid-container").style.backgroundColor =
      "rgb(236, 245, 254)";
    document.getElementById("header-container").style.backgroundColor =
      "rgb(236, 245, 254)";
    document.getElementById("header-money").style.backgroundColor = "white";
    document.getElementById("dispatch").style.setProperty("display", "none");
    document.getElementById("item-screen").classList.remove("itemscreen-size");
    document.getElementById("country").selectedIndex = 0;
    document.getElementById("amount1").selectedIndex = 0;
    document.getElementById("price").selectedIndex = 0;
    document.getElementById("amount1").value = 1;
    setAmount(1);
  }

  function handleCart(isCheckout = false) {
    if (params.Item.name === "BitJem") {
      setIsBitJemLoading(true);
      var price1 = Number(document.getElementById("cp-amount").value);
      var temp;
      if (price1 !== "" || price1 !== null) {
        const data = {
          currency: selectedCrypto,
          to: params.current ? params.current : "USD",
          amount: Number(price1),
        };
        axios
          .post("https://ozchest.com/convertCrypto", data)
          .then((response) => {
            var item = {
              image: cryptoCard,
              brand: "BitJem",
              faceValue: price1,
              price: response.data.cur,
              code: selectedCrypto,
              sale: response.data.cur,
              purchase: response.data.cur,
              sku: "",
              type: "",
              amount: response.data.cur,
              currency: params.current,
            };
            params.setCart(item, price, params.current, 1, Stock);
            setIsBitJemLoading(false);
            if (isCheckout) {
              openCartScreen();
            } else {
              alert.success("Item successfully added to cart");
            }
          });
      } else {
        alert.error("Please enter an amount");
      }
    } else {
      var price1 = selectedProduct.minFaceValue;
      var temp = Number(document.getElementById("amount1").value);
      if (temp <= Stock.quantity) {
        // var data2 = {
        //   from: params.current ? params.current : "EUR",
        //   to: "EUR",
        //   amount: params.usd,
        //   value: 6,
        // };

        // axios.post("https://ozchest.com/convert ", data2).then((response2) => {
        let fValue;
        if (selectedProduct.minFaceValue === selectedProduct.maxFaceValue) {
          fValue = selectedProduct.minFaceValue;
        } else {
          fValue = faceValue;
          if (
            faceValue < selectedProduct.minFaceValue ||
            faceValue > selectedProduct.maxFaceValue
          ) {
            alert.error(
              `Face Value should be between ${selectedProduct.minFaceValue} - ${selectedProduct.maxFaceValue}`
            );
            return;
          }
        }
        var item = {
          id: selectedProduct.productId,
          faceValue: fValue,
          image: params.Item.logoUrl,
          brand: params.Item.name,
          price: price1,
          code: params.Item.currencyCode,
          sale: priceInCurrent,
          purchase: params.purchase,
          sku: Stock.product,
          type: Stock.type,
          amount: 1,
          region: params.Item.countryCode,
        };
        params.setCart(item, price, params.current, temp, Stock);
        if (isCheckout) {
          openCartScreen();
        } else {
          alert.success("Item successfully added to cart");
        }
        // });
      } else {
        alert.error("Item out of stock");
      }
    }
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  function convertDecimal(total) {
    // Ensure total is a number
    const parsedTotal = parseFloat(total);

    // Check if parsedTotal is a valid number
    if (!isNaN(parsedTotal)) {
      // Round to one digit after the decimal point
      const roundedTotal = parsedTotal.toFixed(3);

      // If you need it as a number, you can convert it back
      const finalTotal = parseFloat(roundedTotal);

      return finalTotal;
    } else {
      console.error("Invalid total value");
      return null; // or handle the error accordingly
    }
  }

  // Define your debounced function with a 500ms delay
  const debouncedHandleFaceValueChange = debounce((newValue) => {
    if (newValue !== null && newValue !== undefined && newValue !== "") {
      let price =
        (selectedProduct.price.min / selectedProduct.minFaceValue) * newValue;
      let fee = price * 0.02;
      fee = 0;
      var data2 = {
        from: params.curr[0].price.currencyCode,
        to: params.current ? params.current : "USD",
        amount: price + fee,
        value: 6,
      };

      axios.post("https://ozchest.com/convert ", data2).then((response2) => {
        setFee(fee);
        setPrice(price);
        setPriceInCurrent(response2.data.cur);
        setLoading(false);
      });
    } else {
      setFee(0);
      setPrice(0);
      setPriceInCurrent(0);
      setLoading(false);
    }
  }, 500); // Adjust the debounce delay as needed

  // Handle input change
  const handleFaceValueChange = (e) => {
    setLoading(true);
    const newValue = e.target.value;
    setFaceValue(newValue ?? 0);
    debouncedHandleFaceValueChange(newValue);
  };

  return (
    <div
      className={
        darkMode
          ? "product-container dark-product-container"
          : "product-container"
      }
      id="item-screen"
    >
      <div
        className={
          darkMode
            ? "backbtn-box dark-backbtn-box mt-3 mb-3 p-2 ml-2"
            : "backbtn-box mt-3 mb-3 p-2 ml-2"
        }
      >
        <button onClick={closeItemScreen}>Back</button>
        <div className="header-cart">
          <button className="cart-btn" onClick={openCartScreen}>
            <i className="cart-icon fa fa-shopping-cart mr-2"></i>
            <span className="cart-text"></span>
          </button>
        </div>
      </div>
      <div className="itemdetails-container mt-3">
        <div className="itemdetails-box">
          <div className="item-image">
            <img
              src={
                Card.name === "BitJem"
                  ? cryptoCard
                  : Card.logoUrl !== ""
                  ? Card.logoUrl
                  : altImg
              }
            ></img>
          </div>
          <div className="item-details">
            <div className="item-title mb-2">
              <h1>{Card.name} Cards</h1>
            </div>
            <div className="item-desc">
              <div className="itemdesc-row mb-2">
                <p>
                  {Card.description}
                  <br />
                  <br />
                  {parse(
                    Card.redemptionInstructions
                      ? Card.redemptionInstructions
                      : ""
                  )}
                  {parse(Card.terms ? Card.terms : "")}
                </p>
              </div>
            </div>
            <div
              className={
                darkMode
                  ? "checkout-container dark-checkout-container"
                  : "checkout-container"
              }
            >
              <div className="checkout-box m-4">
                <div className="checkout-row1 mb-3">
                  {isBitJem && (
                    <div className="">
                      <div className="region-title mb-3">
                        <span>Product</span>
                      </div>
                      <select
                        className="form-select"
                        onChange={handleCryptoChange}
                      >
                        {CryptoCurrencies?.map((curr, index) => (
                          <option key={index} value={curr}>
                            {curr}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="region-box2" id="input-div">
                    <div className="region-title mb-3">
                      <span>
                        {isBitJem ? "Enter amount" : "Enter amount in USD"}
                      </span>
                    </div>
                    <input type="Number" id="cp-amount" min="0"></input>
                  </div>

                  <div className="region-box" id="region-div">
                    <div className="region-title mb-3">
                      <span>Choose your region</span>
                    </div>
                    <div className="categories1">
                      <select
                        className="country-btn p-2"
                        name="category1"
                        id="country"
                        onChange={handleChange}
                      >
                        {Countries.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="amount-box" id="amount-div">
                    <div className="amount-title mb-3">
                      <span>Select Quantity</span>
                    </div>
                    <div className="categories1">
                      <input
                        className={
                          darkMode ? "dark-amount-input p-2" : "country-btn p-2"
                        }
                        type="number"
                        name="amount"
                        id="amount1"
                        min="1"
                        max={Stock.quantity} // Set the maximum value as needed
                        defaultValue="1" // Set the default value to 1
                        onChange={handleAmount}
                      />
                    </div>
                  </div>
                </div>
                <div className="checkout-row2 mb-3" id="country-div">
                  <div className="amount-title mb-3">
                    <span>
                      Available Amount ({Stock ? Stock.quantity : ""}
                      {Stock.quantity === 1000 ? "+" : ""})
                    </span>
                  </div>
                  <div className="categories1">
                    <span className="code-span" id="code">
                      Select Amount
                    </span>
                    <select
                      className="country-btn p-2"
                      id="price"
                      onChange={(e) =>
                        handleChange1(
                          e.target.selectedOptions[0].getAttribute("data-count")
                        )
                      }
                    >
                      {!Curr
                        ? ""
                        : Curr.map((item, index) => (
                            <option key={item.id} data-count={index}>
                              {" "}
                              {item.name}
                            </option>
                          ))}
                    </select>
                  </div>
                  {selectedProduct &&
                  selectedProduct.minFaceValue !==
                    selectedProduct.maxFaceValue ? (
                    <div className="cstm-face-value mt-3">
                      <span className="code-span" id="code">
                        Select Amount Between {selectedProduct.minFaceValue} -{" "}
                        {selectedProduct.maxFaceValue}
                      </span>
                      <input
                        className={darkMode ? "dark-face-value" : ""}
                        type="number"
                        id="valueInput"
                        name="valueInput"
                        value={faceValue}
                        onChange={handleFaceValueChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {isBitJemLoading ? (
            <div className="spinner-border" role="status">
            </div>
          ) : (
                <div className="checkout-row mb-3">
                  <button
                    onClick={()=>handleCart(false)}
                    className={
                      darkMode
                        ? "checkcart-btn dark-checkcart-btn p-2"
                        : "checkcart-btn p-2"
                    }
                    disabled={loading}
                  >
                    Add to Cart
                  </button>
                </div>
          )}
                <div className="checkout-row">
                  <button className="checkout-btn p-2"id='checkout-btn' onClick={()=>handleCart(true)} disabled={loading}>
                    {loading ? (
                      <span>Loading...</span>
                    ) : (
                      <>
                        Checkout(
                        <span>
                          {params.current ? params.current : "USD"}{" "}
                          {convertDecimal(priceInCurrent * amount)}
                        </span>
                        )
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="item-desc1">
              <div className="item-title mb-2 mt-2">
                <h1>Description</h1>
              </div>
              <div className="itemdesc-row mb-2">
                <p>
                  {Card.description}
                  <br />
                  <br />
                  {Card.redemptionInstructions}
                  <br />
                  <br />
                  {Card.terms}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ItemScreen);
