import React, {useEffect, useState, useMemo } from "react";
import "./Items.css";
import axios from "axios";
import ItemScreen from "../Product/Product";
import 'bootstrap/dist/css/bootstrap.min.css';
import altImg from '../../Assets/alt.jpg';
import PaginationComponent from "../Pagination-Component/Pagination-Component";
import countryList from 'react-select-country-list';
import cryptoCard from '../../Assets/cryptocard.jpg';

const jsonData = require("./NewBrands.json");
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css"
  integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
  crossorigin="anonymous"
></link>;

function Items(params) {
  const [darkMode, setDarkMode] = useState(params.Mode);
  const [Products, setProducts] = useState([]);
  const [Filter, setFiltered] = useState(jsonData.sort((a, b) => a.name.localeCompare(b.name)));
  const [Searched, setSearched] = useState(jsonData.sort((a, b) => a.name.localeCompare(b.name)));
  const [allProducts, setAllProducts] = useState(jsonData.sort((a, b) => a.name.localeCompare(b.name)));
  const [Brand, setBrand] = useState({});
  const [countries, setCountries] = useState([]);
  const [Curr, setCurr] = useState([]);
  const [Stock, setStock] = useState({});
  const [USDPrice, setUSDPrice] = useState();
  const [PurchasePrice, setPurchasePrice] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  const [itemsLoading, setItemsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(jsonData.sort((a, b) => a.name.localeCompare(b.name)));
  const itemsPerPage = 18;
  let totalPages = Math.floor(filteredItems.length/itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const allCountries = countryList().getData();

  useEffect(() => {
    let filtered;
    if (!itemsLoading) {
      setItemsLoading(true);
    }
    if (searchQuery) {
      // Filter items when there's a search query
      filtered = allProducts.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      // Show current items when there's no search query
      filtered = Searched;
    }
    
    // Calculate the items to display based on filtered items and pagination
    filtered = sortData(filtered);
    const itemsToDisplay = filtered.slice(indexOfFirstItem, indexOfLastItem);
  
    // Set loading to false and update currentItems once
    setFilteredItems(filtered);
    setCurrentItems(itemsToDisplay);
    setItemsLoading(false);
  }, [searchQuery, Searched, indexOfFirstItem, indexOfLastItem]);
  
  useEffect(() => {
    totalPages = Math.ceil(filteredItems.length/itemsPerPage) + 1;
  },[filteredItems]);

  // const itemsToDisplay = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  // setCurrentItems(itemsToDisplay);

  function changeCart(item, price, code, temp, stock) {
    params.setCart(item, price, code, temp, stock);
  }

  // useEffect(() => {
  //   setCurrentItems(filteredItems.slice(indexOfFirstItem, indexOfLastItem));
  // }, [indexOfFirstItem]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     fetch(apiUrl)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setSearched(data);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error);
  //         setLoading(false);
  //       });
  //   }, 2000);
  // }, []);

  useEffect(() => {
    setDarkMode(params.Mode);
    setProducts(jsonData);
  });

  useEffect(() => {
    setTimeout(() => {
      setItemsLoading(false);
    }, 400);
  }, [currentPage]);

  function handlePageChange(page) {
    setItemsLoading(true);
    setCurrentPage(page);
  }
  var Screen;

  function updatePrice(brand) {
    var value = document.getElementById("country").value;
    const data = {
      brand: brand,
      country: value,
      value: 4,
    };
    axios.post("https://ozchest.com/pricelist", data).then((response) => {
      setCurr(response.data);
    });
  }

  function updateStock(brand1, code1, v) {
    var value = document.getElementById("country").value;
    var value1 = document.getElementById("price").value;

    let data;
    if (v === 1 && Curr) {
      data = {
        brand: brand1,
        country: value,
        code: Curr.code,
        price: Number(Curr.price ? Curr.price[0] : 0),
        apitoken: sessionStorage.getItem("apitoken"),
        value: 5,
      };
    } else if (v === 2 && Curr) {
      data = {
        brand: brand1,
        country: value,
        code: Curr.code,
        price: Number(value1),
        apitoken: sessionStorage.getItem("apitoken"),
        value: 5,
      };
    }
    if (brand1 !== undefined && code1 !== undefined) {
      axios.post("https://ozchest.com/stocks", data).then((response) => {
        if (response.data) {
          if (v === 1) {
            document.getElementById("price").value = Curr.price
              ? Curr.price[0]
              : 0;
          }
          setStock(response.data);
          updateFee(data.code, data.price, response.data.purchasePrice);
        }
      });
    }
  }

  function updateFee(from1, amount, price) {
    var data;
    if (params.current === undefined) {
      data = {
        from: from1,
        to: "USD",
        amount: amount,
        value: 6,
      };
    } else {
      data = {
        from: from1,
        to: params.current,
        amount: amount,
        value: 6,
      };
    }
    axios.post("https://ozchest.com/convert ", data).then((response2) => {
      setPurchasePrice(price);
      setUSDPrice(response2.data.cur);
      /* if (price < response2.data.cur) {
        setFee(0);
        console.log("fee" + 0);
      } else if (price > response2.data.cur && response2.data.cur < 50) {
        setFee(2.3);
        console.log("fee" + 2.3);
      } else if (price > response2.data.cur && response2.data.cur > 50) {
        setFee(3.4);
        console.log("fee" + 3.3);
      }*/
    });
  }
  function handleFilter(event) {
    setItemsLoading(true);
    if (event.target.value === "All") {
      let data1 = sortData(Products);
      let data2 = sortData(allProducts);
      setFiltered(data1);
      setSearched(data1);
      setFilteredItems(data2);
    } else {
      let filtered = allProducts?.filter(item => item?.categories?.some(category => category?.name === event.target.value));
      filtered = sortData(filtered);
      setFiltered(filtered);
      setFilteredItems(filtered)
      setSearched(filtered);
    }
    setCurrentPage(1);
  }
  
  function openItemScreen(index) {
    const updatedIndex = ((currentPage - 1) * itemsPerPage) + index;
    const currentProduct = filteredItems[updatedIndex];
    if (filteredItems[index].name === "BitJem") {
      document
        .getElementById("checkout-btn")
        .style.setProperty("display", "none");
      document
        .getElementById("input-div")
        .style.setProperty("display", "block");
      document
        .getElementById("region-div")
        .style.setProperty("display", "none");
      document
        .getElementById("country-div")
        .style.setProperty("display", "none");
      document
        .getElementById("amount-div")
        .style.setProperty("display", "none");
      setUSDPrice("");
    } else {
      document
        .getElementById("checkout-btn")
        .style.setProperty("display", "block");
      document.getElementById("input-div").style.setProperty("display", "none");
      document
        .getElementById("region-div")
        .style.setProperty("display", "block");
      document
        .getElementById("country-div")
        .style.setProperty("display", "block");
      document
        .getElementById("amount-div")
        .style.setProperty("display", "block");
      const data = {
        brand: Searched[index].name,
        amount: 20,
        currency: "GBP",
        value: 3,
      };
      let countries = [];
      countries.push(currentProduct?.countryCode);
      setCountries(countries);
      setCurr(currentProduct?.products);
      updateFee(currentProduct.currencyCode, currentProduct.products[0].minFaceValue, currentProduct.products[0].price.min);
      /* axios
        .post("https://ozchest.com/savecurrency", data)
        .then((response1) => {
          console.log("success");
        });*/
      // axios.post("https://ozchest.com/getcurrencies", data).then((response) => {
      //   if (response.data) {
      //     setCountries(response.data.countries);
      //     setCurr(response.data.curr);
      //     const data = {
      //       brand: Searched[index].Brand,
      //       country: response.data.countries[0],
      //       code: response.data.curr.code,
      //       price: Number(response.data.curr.price[0]),
      //       apitoken: sessionStorage.getItem("apitoken"),
      //       value: 5,
      //     };

      //     axios.post("https://ozchest.com/stocks", data).then((response1) => {
      //       setStock(response1.data);
      //       updateFee(data.code, data.price, response1.data.purchasePrice);
      //     });
      //   }
      // });
    }
    document.getElementById("item-screen").style.setProperty("width", "60%");
    document.getElementById("dispatch").style.setProperty("display", "block");
    document.getElementById("item-screen").classList.add("itemscreen-size");
    setBrand(currentProduct);
  }

  
  const handleSortingFilter = (event) => {
    const selectedOption = event.target.value;

    let sortedData = [...filteredItems];

    switch (selectedOption) {
      case 'Name:A to Z':
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'Name:Z to A':
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'Date:Oldest to Latest':
        sortedData.sort((a, b) => new Date(a.modifiedDate) - new Date(b.modifiedDate));
        break;
      default:
        break;
    }
    setFilteredItems(sortedData);
    const itemsToDisplay = sortedData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(itemsToDisplay);
  };

  function handleCountryChange(event) {
    let items;
    if (event.target.value !== "") {
      items = allProducts.filter(item => item.countryCode === event.target.value);
      items = sortData(items);
      setFilteredItems(items);
      setFiltered(items);
      setSearched(items);
    } else {
      items = sortData(allProducts);
      setFilteredItems(items);
      setFiltered(items);
      setSearched(items);
    }
  }

  function sortData(data) {
    let option = document.getElementById("category1").value;
    let sortedData = [...data];

    switch (option) {
      case 'Name:A to Z':
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'Name:Z to A':
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'Date:Oldest to Latest':
        sortedData.sort((a, b) => new Date(a.modifiedDate) - new Date(b.modifiedDate));
        break;
      default:
        break;
    }

    return sortedData;
  }

  return (
    <div className="items-outercontainer">
      <div className="items-container mt-5">
        <div className="categories mr-4">
        <div className="categories1" id="categories1">
          <select
            className={
              darkMode
                ? "country-btn dark-country-btn p-2"
                : "country-btn p-2"
            }
            name="category"
            id="category"
            onChange={handleCountryChange}
          >
            <option value="">Select a Country</option>
            {allCountries.map((country,index) => (
              <option key={index} value={country.value}>
                {country.label}
              </option>
            ))}
          </select>
        </div>
          <div
            className={
              darkMode
                ? "categories2 dark-categories2 mt-5 p-3"
                : "categories2 mt-5 p-3"
            }
            id="categories2"
          >
            <h3 className="cat-box">Categories</h3>
            <div onChange={handleFilter}>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="All"
                ></input>
                <label htmlFor="all">All</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Fashion & Accessories"
                ></input>
                <label htmlFor="Fashion & Accessories">Fashion & Accessories</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Entertainment"
                ></input>
                <label htmlFor="Entertainment">Entertainment</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Sports Lifestyle"
                ></input>
                <label htmlFor="Sports Lifestyle">Sports Lifestyle</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Airlines & Hotels"
                ></input>
                <label htmlFor="Airlines & Hotels">Airlines & Hotels</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Gaming"
                ></input>
                <label htmlFor="Gaming">Gaming</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Dining & Restaurants"
                ></input>
                <label htmlFor="Dining & Restaurants">Dining & Restaurants</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="eCommerce"
                ></input>
                <label htmlFor="eCommerce">eCommerce</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Travel"
                ></input>
                <label htmlFor="Travel">Travel</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Electronics"
                ></input>
                <label htmlFor="Electronics">Electronics</label>
              </div>
              <div className="radios">
                <input
                  className="mr-2"
                  type="radio"
                  id="filter"
                  name="categories"
                  value="Prepaid Cards"
                ></input>
                <label htmlFor="Prepaid Cards">Prepaid Cards</label>
              </div>
            </div>
          </div>
        </div>
        <div className="search_list">
          <div className="search">
            <div
              className={
                darkMode ? "searching dark-searching p-2" : "searching p-2"
              }
            >
              <div className="search-box ml-3">
                <div className="search_symbol">
                  <i className="fa fa-search"></i>
                </div>
                <div className="search-input">
                  <input
                    className="input1"
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search products by name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  ></input>
                </div>
              </div>
              <button
                className={
                  darkMode
                    ? "search_button dark-search_button mr-5"
                    : "search_button mr-5 "
                }
              >
                Search
              </button>
            </div>
            <div className="sorting" id="sorting">
              <select
                className={
                  darkMode
                    ? "country-btn dark-country-btn p-2"
                    : "country-btn p-2"
                }
                name="category"
                id="category1"
                onChange={handleSortingFilter}
              >
                <optgroup className="grp">
                  <option id="cat-option" key='1'>Name:A to Z</option>
                  <option id="cat-option" key='2'>Name:Z to A</option>
                  <option id="cat-option" key='3'>Date:Oldest to Latest</option>
                </optgroup>
              </select>
            </div>
          </div>
          {loading ? (
            <div className="text-center mt-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
          <>
          <div className="list mt-5">
            {itemsLoading ? (
              <div className="text-center mt-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        ) : (
          <>
            <div
              className={
                darkMode
                  ? "grid-container1 dark-grid-container1"
                  : "grid-container1"
              }
            >
              { itemsLoading ? (
              <div className="text-center mt-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        ) : (currentItems.map((item, index) => (
          <div className="grid-item card-p" key={index}>
          <a onClick={() => openItemScreen(index)}>
            <img
              className="card"
              src={item.name === 'BitJem' ? cryptoCard : (item.logoUrl !== "" ? item.logoUrl : altImg)}
              alt={altImg}
            />
          </a>
          {Screen}
          <div className="card-body">
            <h6
              className={
                darkMode ? "card-text dark-card-text" : "card-text"
              }
              id="card-subtitle"
            >
              {item.name}
            </h6>
            {/* Add the small square */}
            <div
              className="currency-square"
            >
              {item.currencyCode}
            </div>
          </div>
        </div>        
                )))}
            </div>
  
            <PaginationComponent currentPage={currentPage} totalItems={filteredItems} handlePageChange={handlePageChange} itemsPerPage={itemsPerPage}/>
            </>
        )}
          </div>
          </>
          )}
          <ItemScreen
                Mode={darkMode}
                Item={Brand}
                setCart={changeCart}
                country={countries}
                curr={Curr}
                stock={Stock}
                updateprice={updatePrice}
                updateFee={updateFee}
                usd={USDPrice}
                current={params.current}
                purchase={PurchasePrice}
              ></ItemScreen>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
export default React.memo(Items);
