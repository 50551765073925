import { React, useEffect, useState } from "react";
import "./Payment.css";
import axios from "axios";
import lock from "../../Assets/lock.PNG";
import { useAlert } from "react-alert";
import Paystack from "../Paystack/Paystack";
import { useHireContext } from "../../App.js";

function PaymentScreen() {
  const [generate, setGenerate] = useState(false);
  const [change, setChange] = useState(false);
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0);
  const [giftCard, setGiftCard] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptedTOS, setAcceptedTOS] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // Function to handle checkbox toggle
  const handleTOSChange = () => {
    setAcceptedTOS(!acceptedTOS);
  };

  const getUrl = async () => {
    try {
      const response = await axios.post(
        "https://ozchest.com/getPaymentWallUrl",
        {
          email: sessionStorage.getItem("email"),
          amount: Number(amount),
          currency: document.getElementById("refillcurrency").value,
        }
      );
      const paymentUrl = response.data.paymentUrl;

      if (paymentUrl) {
        return paymentUrl;
      }
    } catch (error) {
      console.error("Error fetching payment URL:", error);
    }
  };

  const handlePaymentWall = () => {
    const windowName = "Paymentwall"; // Name of the new window
    const features =
      "width=600,height=400,top=100,left=100,scrollbars=yes,resizable=yes"; // Window features

    const paymentwallWindow = window.open(windowName, features);

    getUrl().then(function (url) {
      paymentwallWindow.location = url;
    });
  };

  const alert = useAlert();
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("nowpayment");

  // check again
  const [buyer, setBuyer] = useState(
    sessionStorage.getItem("token")
      ? {
          email: sessionStorage.getItem("email"),
          balance: sessionStorage.getItem("balance"),
        }
      : {
          email: "",
          balance: 0,
        }
  );
  const { currentCurrency, setCurrentCurrency } = useHireContext();
  useEffect(() => {
    const temp = {
      email: sessionStorage.getItem("email"),
      balance: sessionStorage.getItem("balance"),
    };
    setBuyer(temp);
  }, [sessionStorage.getItem("token")]);

  useEffect(() => {
    const temp = {
      email: sessionStorage.getItem("email"),
      balance: sessionStorage.getItem("balance"),
    };
    setBuyer(temp);
  }, [sessionStorage.getItem("balance")]);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      document
        .getElementById("payment-div1")
        .style.setProperty("display", "none");
      document
        .getElementById("payment-div2")
        .style.setProperty("display", "none");
      document
        .getElementById("payment-div3")
        .style.setProperty("display", "flex");
    }
  });

  // check again
  useEffect(() => {
    if (!sessionStorage.getItem("token") && buyer.email === null) {
      setBuyer({
        email: document.getElementById("email1").value,
        balance: 0,
      });
    }
  }, []);

  function handleSubmit1() {
    const email = document.getElementById("email1").value;
    const name = document.getElementById("name1").value;
    const name2 = document.getElementById("name2").value;
    const password = document.getElementById("password").value;
    const confirmPassword = document.getElementById("confirmPassword").value;
    const country = document.getElementById("country1").value;
    const city = document.getElementById("city").value;
    const street = document.getElementById("street").value;
    const zip = document.getElementById("zip-code").value;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;

    if (!emailRegex.test(email)) {
      alert.error("Please enter a valid email address");
      return;
    }

    if (!passwordRegex.test(password)) {
      alert.error(
        "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return;
    }

    if (password !== confirmPassword) {
      alert.error("Password and Confirm Password do not match");
      return;
    }

    if (
      email !== "" &&
      name !== "" &&
      name2 !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      country !== "" &&
      city !== "" &&
      street !== "" &&
      zip !== ""
    ) {
      setGenerate(true);
      let tempBuyer = buyer;
      if (buyer.email === null) {
        tempBuyer = {
          email: email.toLowerCase(),
          balance: 0,
        };
        setBuyer(tempBuyer);
      }
      const user = {
        email: email.toLowerCase(),
        name: name + " " + name2,
        password: password,
        confirmPassword: confirmPassword,
        address: street + "," + city + "," + country,
        zip: zip,
        value: 1,
      };

      axios
        .post("https://ozchest.com/generate", user)
        .then((result) => {
          if (result) {
            alert.success("Successfully registered");

            // Clear input fields
            document.getElementById("email1").value = "";
            document.getElementById("name1").value = "";
            document.getElementById("name2").value = "";
            document.getElementById("password").value = "";
            document.getElementById("confirmPassword").value = "";
            document.getElementById("country1").value = "";
            document.getElementById("city").value = "";
            document.getElementById("street").value = "";
            document.getElementById("zip-code").value = "";

            setBuyer({
              email: result.data.BuyerExist.email,
              balance: result.data.BuyerExist.balance,
            });

            document
              .getElementById("payment-div1")
              .style.setProperty("display", "none");
            document
              .getElementById("payment-div2")
              .style.setProperty("display", "none");
            document
              .getElementById("payment-div5")
              .style.setProperty("display", "flex");

            const name = result.data.BuyerExist.name.split(" ");
            document.getElementById("name11").innerHTML = name[0];
            document.getElementById("name22").innerHTML = name[1];
            document.getElementById("email11").innerHTML =
              result.data.BuyerExist.email;

            sessionStorage.setItem("balance", result.data.BuyerExist.balance);
            sessionStorage.setItem("email", result.data.BuyerExist.email);
            sessionStorage.setItem("fname", name[0]);
            sessionStorage.setItem("lname", name[1]);

            // Send OTP to the registered email
            axios
              .post("https://ozchest.com/send-otp", { email: user.email })
              .then((otpResponse) => {
                alert.success("OTP sent to email for verification");
              })
              .catch((otpError) => {
                alert.error("Failed to send OTP");
                console.error(otpError);
              });
          }
        })
        .catch((error) => {
          alert.error("Email already registered");
        });
    } else {
      alert.error("Please enter all fields");
    }
  }

  const handleOtpVerification = async () => {
    const code = document.getElementById("code").value;
    const email = buyer.email;

    // Validate OTP: it should be a six-digit number
    const otpRegex = /^\d{6}$/;

    if (!otpRegex.test(code)) {
      alert("Please enter a valid 6-digit OTP code.");
      return;
    }

    try {
      // Send the OTP and email to the /verify-otp endpoint
      const response = await axios.post("https://ozchest.com/verify-otp", {
        email: email,
        otp: code,
      });

      // Check if the response status is 200 (success)
      if (response.status === 200) {
        alert.success("OTP verification successful!");
        // Perform any additional actions upon successful verification
        document.getElementById("code").value = "";

        var name = response.data.BuyerExist.name.split(" ");
        document.getElementById("name11").innerHTML = name[0];
        document.getElementById("name22").innerHTML = name[1];
        document.getElementById("email11").innerHTML =
          response.data.BuyerExist.email;

        sessionStorage.setItem("token", response.data.token); // decode your token here
        sessionStorage.setItem("balance", response.data.BuyerExist.balance);
        sessionStorage.setItem("email", response.data.BuyerExist.email);
        sessionStorage.setItem("fname", name[0]);
        sessionStorage.setItem("lname", name[1]);
        document
          .getElementById("payment-div1")
          .style.setProperty("display", "none");
        document
          .getElementById("payment-div2")
          .style.setProperty("display", "none");
        document
          .getElementById("payment-div5")
          .style.setProperty("display", "none");
        document
          .getElementById("payment-div3")
          .style.setProperty("display", "flex");
      } else {
        alert.error("OTP verification failed. Please try again.");
      }
    } catch (error) {
      alert.error("Error verifying OTP. Please check and try again.");
    }
  };

  const handleResendOtp = async () => {
    setIsButtonDisabled(true);
    axios
      .post("https://ozchest.com/send-otp", {
        email: buyer.email.toLowerCase(),
      })
      .then((otpResponse) => {
        alert.success("OTP resent to email");
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 180000); // 3 minutes
      })
      .catch((otpError) => {
        alert.error("Failed to send OTP");
        console.error(otpError);
        setIsButtonDisabled(false);
      });
  };
  // check again
  function handleSubmit2() {
    const email = document.getElementById("loginEmail").value;
    const password = document.getElementById("loginPassword").value;

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Password validation regex (at least 8 characters, one uppercase, one lowercase, one number, one special character)
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;

    // Validate email
    if (!emailRegex.test(email)) {
      alert.error("Please enter a valid email address");
      return;
    }

    // Validate password security
    if (!passwordRegex.test(password)) {
      alert.error(
        "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return;
    }

    if (email !== "" && password !== "") {
      const user = {
        buyer: {
          email: email.toLowerCase(),
          password: password,
        },
        value: 2,
      };

      axios
        .post("https://ozchest.com/connect", user)
        .then((result) => {
          if (result) {
            alert.success("Login successful");
            setBuyer({
              email: buyer.email,
              balance: result.data.BuyerExist.balance,
            });

            if (result.data.BuyerExist.verified) {
              // Show div3 and hide others
              document
                .getElementById("payment-div1")
                .style.setProperty("display", "none");
              document
                .getElementById("payment-div2")
                .style.setProperty("display", "none");
              document
                .getElementById("payment-div3")
                .style.setProperty("display", "flex");
              document
                .getElementById("payment-div5")
                .style.setProperty("display", "none"); // Ensure div5 is hidden

              var name = result.data.BuyerExist.name.split(" ");
              document.getElementById("name11").innerHTML = name[0];
              document.getElementById("name22").innerHTML = name[1];
              document.getElementById("email11").innerHTML =
                result.data.BuyerExist.email;

              sessionStorage.setItem("token", result.data.token); // decode your token here
              sessionStorage.setItem("balance", result.data.BuyerExist.balance);
              sessionStorage.setItem("email", result.data.BuyerExist.email);
              sessionStorage.setItem("fname", name[0]);
              sessionStorage.setItem("lname", name[1]);
            } else {
              // Show div5 and hide others
              axios
                .post("https://ozchest.com/send-otp", {
                  email: result.data.BuyerExist.email,
                })
                .then((otpResponse) => {
                  alert.success("OTP sent to email for verification");
                })
                .catch((otpError) => {
                  alert.error("Failed to send OTP");
                  console.error(otpError);
                });
              sessionStorage.setItem("email", result.data.BuyerExist.email);
              document
                .getElementById("payment-div1")
                .style.setProperty("display", "none");
              document
                .getElementById("payment-div2")
                .style.setProperty("display", "none");
              document
                .getElementById("payment-div3")
                .style.setProperty("display", "none"); // Ensure div3 is hidden
              document
                .getElementById("payment-div5")
                .style.setProperty("display", "flex");
            }
          }
        })
        .catch((error) => {
          alert.error("Invalid email or password");
        });
    } else {
      alert.error("Please enter both email and password");
    }
  }

  function handleResetPassword() {
    document
      .getElementById("payment-div1")
      .style.setProperty("display", "none");
    document
      .getElementById("payment-div2")
      .style.setProperty("display", "none");
    document
      .getElementById("payment-div7")
      .style.setProperty("display", "flex");
  }

  const handleVerifyResetPassword = () => {
    try {
      const email = document.getElementById("resetEmail").value;
      const otp = document.getElementById("resetCode").value;
      const newPassword = document.getElementById("resetPassword").value;
      const confirmPassword = document.getElementById(
        "resetConfirmPassword"
      ).value;

      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;

      if (!passwordRegex.test(newPassword)) {
        alert.error(
          "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character"
        );
        return;
      }

      if (newPassword !== confirmPassword) {
        alert.error("New Password and Confirm Password do not match");
        return;
      }
      axios
        .post("https://ozchest.com/reset-password", {
          email: email.toLowerCase(),
          otp: otp,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        })
        .then((otpResponse) => {
          alert.success("Password reset successfull");

          document
            .getElementById("payment-div2")
            .style.setProperty("display", "none");

          document
            .getElementById("payment-div7")
            .style.setProperty("display", "none");
          document
            .getElementById("payment-div6")
            .style.setProperty("display", "none");
          document
            .getElementById("payment-div1")
            .style.setProperty("display", "flex");
        })
        .catch((otpError) => {
          alert.error(
            "Failed to reset password please try again or contact support"
          );
          console.error(otpError);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleResetSendOtp = () => {
    try {
      const email = document.getElementById("resetEmail").value;
      axios
        .post("https://ozchest.com/send-otp", {
          email: email.toLowerCase(),
        })
        .then((otpResponse) => {
          setBuyer({
            email: email.toLowerCase(),
            balance: 0,
          });
          alert.success("OTP sent to email");
          document
            .getElementById("payment-div1")
            .style.setProperty("display", "none");
          document
            .getElementById("payment-div2")
            .style.setProperty("display", "none");

          document
            .getElementById("payment-div7")
            .style.setProperty("display", "none");
          document
            .getElementById("payment-div6")
            .style.setProperty("display", "flex");
        })
        .catch((otpError) => {
          alert.error("Failed to send OTP");
          console.error(otpError);
        });
    } catch (error) {
      console.error(error);
    }
  };

  function copy() {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    alert.show("Copied the text: " + copyText.value);
  }

  function handleSignOut() {
    const token = sessionStorage.getItem("apitoken");
    sessionStorage.clear();
    //sessionStorage.setItem("apitoken", token);
    setBuyer({
      email: "",
      balance: 0,
    });

    document
      .getElementById("payment-div1")
      .style.setProperty("display", "flex");
    document
      .getElementById("payment-div2")
      .style.setProperty("display", "none");
    document
      .getElementById("payment-div3")
      .style.setProperty("display", "none");
    handleConnect();
    document
      .getElementById("payment-div4")
      .style.setProperty("display", "none");
  }
  function handleChange(event) {
    setChange(true);
    const { name, value } = event.target;
    setBuyer((prevValue) => {
      return { ...prevValue, [name]: value };
    });
    console.log(buyer);
  }

  function handleRegister() {
    document
      .getElementById("payment-div1")
      .style.setProperty("display", "none");
    document
      .getElementById("payment-div2")
      .style.setProperty("display", "block");
    document
      .getElementById("payment-div3")
      .style.setProperty("display", "none");
  }

  function handleConnect() {
    document
      .getElementById("payment-div1")
      .style.setProperty("display", "flex");
    document
      .getElementById("payment-div2")
      .style.setProperty("display", "none");
    document
      .getElementById("payment-div3")
      .style.setProperty("display", "none");
    document
      .getElementById("payment-div5")
      .style.setProperty("display", "none");
    document
      .getElementById("payment-div6")
      .style.setProperty("display", "none");
    document
      .getElementById("payment-div7")
      .style.setProperty("display", "none");
  }
  function handlePromo() {
    var code = document.getElementById("code").value;
    var discount = Number(document.getElementById("discount").value);
    const data2 = {
      code: code,
      discount: discount,
    };
    axios
      .post("https://ozchest.com/createpromo", data2)
      .then((response2) => {
        if (response2) {
          alert.success("Promo code successfully created");
        }
      })
      .catch((error) => {
        alert.error("Something went wrong");
      });
  }

  function convertDecimal(total) {
    let x = (total?.toString() + ".0").split(".");
    return parseFloat(x[0] + "." + x[1].substr(0, 2));
  }

  function handleRefill() {
    if (acceptedTOS) {
      var amount = Number(document.getElementById("amount").value);
      var markup = amount * 0.02;
      var method = document.getElementById("method").value;
      var curr = document.getElementById("refillcurrency").value;

      const data2 = {
        from: curr,
        to: "USD",
        amount: amount,
        value: 6,
      };
      axios.post("https://ozchest.com/convert ", data2).then((response2) => {
        var data = JSON.stringify({
          price_amount: response2.data.cur,
          price_currency: "usd",
          order_id: sessionStorage.getItem("email"),
          order_description: "Ozchest reload",
          ipn_callback_url: "https://ozchest.com/ipn",
          success_url: "https://ozchest.com",
          cancel_url: "https://google.com",
        });

        var config = {
          method: "post",
          url: "https://api.nowpayments.io/v1/invoice",
          headers: {
            "x-api-key": "535HF7P-YHW4KWY-NC8VTAW-931RC7Q",
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            if (response) {
              let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
  width=500,height=500`;
              window.open(response.data.invoice_url, "test", params);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }

  const handleOzchestGiftCard = async () => {
    try {
      const email = sessionStorage.getItem("email");
      const code = giftCard;

      // Make a POST request to redeem the gift card
      const response = await axios.post(
        "https://Business.ozchest.com/gift-cards/redeem",
        { email, code }
      );

      // Handle a successful redemption
      if (response.status === 200) {
        alert.success(response.data.message);
        setGiftCard("");

        sessionStorage.setItem("balance", response.data.balance);
      }
    } catch (error) {
      // Handle specific error responses based on message
      if (error.response && error.response.status === 404) {
        alert.error("Gift card code is invalid.");
      } else if (error.response && error.response.status === 400) {
        alert.error("Gift card has already been used.");
      } else {
        alert.error("Unable to redeem gift card.");
      }
    }
  };

  return (
    <div className="payment-container" id="payment">
      <div className="payment-div1" id="payment-div1">
        <div className="logo" id="logo">
          <img src={lock} width="100" height="100"></img>
        </div>
        <div className="payment-innerbox" id="inner-div">
          <h2 className="head2 ml-2" id="head2">
            Login
          </h2>
          <div className="order-name1 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="Your E-Mail"
              id="loginEmail"
            ></input>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2 password-wrapper">
            <input
              className="password-input"
              placeholder="Password"
              id="loginPassword"
              type={showPassword ? "text" : "password"} // Toggle between password and text
            />
            <i
              className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} // Toggle between icons
              onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
            ></i>
          </div>
        </div>

        <div className="btn-div">
          <button
            id="connect1"
            className="connect-btn mt-4 pt-1 pb-1"
            onClick={handleSubmit2}
          >
            Login
          </button>
        </div>
        <p className="para mt-4" id="para">
          Forgot Password?
          <button onClick={handleResetPassword}>Reset</button>
        </p>
        <p className="para" id="para">
          Dont have an account?
          <button onClick={handleRegister}> Register</button>
        </p>
      </div>
      <div className="payment-div5" id="payment-div5">
        <div className="payment-innerbox" id="inner-div">
          <h2 className="head2 ml-2" id="head2">
            Verify Email
          </h2>
          <div className="order-info1 mt-3">
            <h5 style={{ color: "white" }}>OTP sent to this email</h5>
            <h5 style={{ fontWeight: "bold", color: "white" }}>
              {buyer.email}
            </h5>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="Email verification code"
              id="code"
            ></input>
          </div>
        </div>

        <div className="btn-div">
          <button
            id="connect1"
            className="connect-btn mt-4 pt-1 pb-1"
            onClick={handleOtpVerification}
          >
            Verify Email
          </button>
        </div>
        <p className="para mt-4" id="para">
          Did not recieve a code?
          <button onClick={handleResendOtp} disabled={isButtonDisabled}>
            Resend
          </button>
        </p>
        <p className="para" id="para">
          Already verified?
          <button onClick={handleConnect}>Login</button>
        </p>
      </div>

      <div className="payment-div7" id="payment-div7">
        <div className="payment-innerbox" id="inner-div">
          <h2 className="head2 ml-2" id="head2">
            Enter Registered Email
          </h2>

          <div className="order-name1 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="Registered Email"
              id="resetEmail"
            ></input>
          </div>
        </div>

        <div className="btn-div">
          <button
            id="connect1"
            className="connect-btn mt-4 pt-1 pb-1"
            onClick={handleResetSendOtp}
          >
            Send OTP
          </button>
        </div>

        <p className="para mt-4" id="para">
          Remember Password?
          <button onClick={handleConnect}>Login</button>
        </p>
      </div>

      <div className="payment-div6" id="payment-div6">
        <div className="payment-innerbox" id="inner-div">
          <h2 className="head2 ml-2" id="head2">
            Reseet Password
          </h2>
          <div className="order-info1 mt-3">
            <h5 style={{ color: "white" }}>OTP sent to this email</h5>
            <h5 style={{ fontWeight: "bold", color: "white" }}>
              {buyer.email}
            </h5>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="OTP code"
              id="resetCode"
            ></input>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2 password-wrapper">
            <input
              className="password-input"
              placeholder="New Password"
              id="resetPassword"
              type={showPassword ? "text" : "password"} // Toggle between password and text
            />
            <i
              className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} // Toggle between icons
              onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
            ></i>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2 password-wrapper">
            <input
              className="confirm-password-input"
              placeholder="Confirm Password"
              id="resetConfirmPassword"
              type={showConfirmPassword ? "text" : "password"} // Toggle between password and text
            />
            <i
              className={showConfirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} // Toggle between icons
              onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle showConfirmPassword state
            ></i>
          </div>
        </div>

        <div className="btn-div">
          <button
            id="connect1"
            className="connect-btn mt-4 pt-1 pb-1"
            onClick={handleVerifyResetPassword}
          >
            Reset Password
          </button>
        </div>
        <p className="para mt-4" id="para">
          Did not recieve a code?
          <button onClick={handleResendOtp} disabled={isButtonDisabled}>
            Resend
          </button>
        </p>
        <p className="para" id="para">
          Remember password?
          <button onClick={handleConnect}>Login</button>
        </p>
      </div>

      <div className="payment-div2" id="payment-div2">
        <div className="payment-innerbox" id="inner-div">
          <h2 className="head2 ml-2" id="head2">
            Register
          </h2>

          <div className="order-name1 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="First Name"
              id="name1"
            ></input>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="Last Name"
              id="name2"
            ></input>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="Your E-Mail"
              id="email1"
            ></input>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2 password-wrapper">
            <input
              className="password-input"
              placeholder="Password"
              id="password"
              type={showPassword ? "text" : "password"} // Toggle between password and text
            />
            <i
              className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} // Toggle between icons
              onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
            ></i>
          </div>
          <div className="order-name1 mt-1 ml-2 mr-2 password-wrapper">
            <input
              className="confirm-password-input"
              placeholder="Confirm Password"
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"} // Toggle between password and text
            />
            <i
              className={showConfirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} // Toggle between icons
              onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle showConfirmPassword state
            ></i>
          </div>
          <div className="order-name2 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="Country"
              id="country1"
            ></input>
            <input className="email-input" placeholder="City" id="city"></input>
          </div>
          <div className="order-name2 mt-1 ml-2 mr-2">
            <input
              className="email-input"
              placeholder="Street"
              id="street"
            ></input>
            <input
              className="email-input"
              placeholder="Zip Code"
              id="zip-code"
            ></input>
          </div>
        </div>
        <div className="btn-div">
          <button
            id="connect"
            className="generate-btn pt-1 pb-1"
            onClick={handleSubmit1}
          >
            Submit
          </button>
        </div>
        <p className="para1 mt-4" id="para1">
          Already have an account!
          <button onClick={handleConnect}> Login</button>
        </p>
      </div>
      <div className="payment-div3" id="payment-div3">
        <h2 className="head3" id="head3">
          Welcome{" "}
        </h2>
        <div className="name-div mt-1">
          <div className="name-box">
            <span>First Name</span>
            <h6 id="name11">
              {sessionStorage.getItem("token")
                ? sessionStorage.getItem("fname")
                : ""}
            </h6>
          </div>
          <div className="name-box">
            <span>Last Name</span>
            <h6 id="name22">
              {sessionStorage.getItem("token")
                ? sessionStorage.getItem("lname")
                : ""}
            </h6>{" "}
          </div>
        </div>

        <div className="order-name11 mt-2">
          <div className="name-box2">
            <span>Email</span>
            <h6 id="email11">
              {sessionStorage.getItem("token")
                ? sessionStorage.getItem("email")
                : ""}
            </h6>
          </div>
        </div>

        <div className="btn-div">
          <button
            id="signout"
            className=" mt-4 mb-2 refill-btn"
            onClick={handleSignOut}
          >
            Logout
          </button>
        </div>
        <p className="bal mt-4" id="bal">
          {" "}
          Balance = {convertDecimal(buyer?.balance)}
        </p>
        <div className="payment-box" id="payment-box">
          <div className="payment-bar">
            {selectedPaymentMethod !== "ozchestGiftCard" ? (
              <input
                id="amount"
                min="0"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              ></input>
            ) : (
              <input
                id="giftCard"
                type="text"
                value={giftCard}
                onChange={(e) => setGiftCard(e.target.value)}
              ></input>
            )}
            {selectedPaymentMethod === "paystack" ||
            selectedPaymentMethod === "ozchestGiftCard" ? (
              <select id="refillcurrency">
                <option value="USD">USD</option>
              </select>
            ) : selectedPaymentMethod === "PaymentWall" ? (
              <select id="refillcurrency">
                <option value="USD">USD</option>
                <option value="AED">AED</option>
                <option value="ALL">ALL</option>
                <option value="ARS">ARS</option>
                <option value="AUD">AUD</option>
                <option value="AZN">AZN</option>
                <option value="BAM">BAM</option>
                <option value="BDT">BDT</option>
                <option value="BGN">BGN</option>
                <option value="BHD">BHD</option>
                <option value="BOB">BOB</option>
                <option value="BRL">BRL</option>
                <option value="BYN">BYN</option>
                <option value="CAD">CAD</option>
                <option value="CHF">CHF</option>
                <option value="CNY">CNY</option>
                <option value="COP">COP</option>
                <option value="CRC">CRC</option>
                <option value="CZK">CZK</option>
                <option value="DKK">DKK</option>
                <option value="DOP">DOP</option>
                <option value="DZD">DZD</option>
                <option value="EGP">EGP</option>
                <option value="ETB">ETB</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="GEL">GEL</option>
                <option value="GTQ">GTQ</option>
                <option value="HKD">HKD</option>
                <option value="HNL">HNL</option>
                <option value="HRK">HRK</option>
                <option value="HUF">HUF</option>
                <option value="IDR">IDR</option>
                <option value="ILS">ILS</option>
                <option value="INR">INR</option>
                <option value="IQD">IQD</option>
                <option value="IRR">IRR</option>
                <option value="JMD">JMD</option>
                <option value="JOD">JOD</option>
                <option value="JPY">JPY</option>
                <option value="KES">KES</option>
                <option value="KRW">KRW</option>
                <option value="KWD">KWD</option>
                <option value="LBP">LBP</option>
                <option value="LKR">LKR</option>
                <option value="LYD">LYD</option>
                <option value="MAD">MAD</option>
                <option value="MDL">MDL</option>
                <option value="MXN">MXN</option>
                <option value="MYR">MYR</option>
                <option value="NGN">NGN</option>
                <option value="NIO">NIO</option>
                <option value="NOK">NOK</option>
                <option value="NZD">NZD</option>
                <option value="OMR">OMR</option>
                <option value="PAB">PAB</option>
                <option value="PEN">PEN</option>
                <option value="PHP">PHP</option>
                <option value="PKR">PKR</option>
                <option value="PLN">PLN</option>
                <option value="PYG">PYG</option>
                <option value="QAR">QAR</option>
                <option value="RON">RON</option>
                <option value="RSD">RSD</option>
                <option value="RUB">RUB</option>
                <option value="SAR">SAR</option>
                <option value="SDG">SDG</option>
                <option value="SEK">SEK</option>
                <option value="SGD">SGD</option>
                <option value="SYP">SYP</option>
                <option value="THB">THB</option>
                <option value="TND">TND</option>
                <option value="TRY">TRY</option>
                <option value="TWD">TWD</option>
                <option value="UAH">UAH</option>
                <option value="UYU">UYU</option>
                <option value="VEF">VEF</option>
                <option value="VND">VND</option>
                <option value="XAF">XAF</option>
                <option value="XOF">XOF</option>
                <option value="YER">YER</option>
                <option value="ZAR">ZAR</option>
              </select>
            ) : (
              <select id="refillcurrency">
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="CAD">CAD</option>
                <option value="AED">AED</option>
                <option value="AFN">AFN</option>
                <option value="ALL">ALL</option>
                <option value="AMD">AMD</option>
                <option value="ARD">ARD</option>
                <option value="AUD">AUD</option>
                <option value="AZN">AZN</option>
                <option value="BAM">BAM</option>
                <option value="BDT">BDT</option>
                <option value="BGN">BGN</option>
                <option value="BHD">BHD</option>
                <option value="BIF">BIF</option>
                <option value="BND">BND</option>
                <option value="BOB">BOB</option>
                <option value="BRL">BRL</option>
                <option value="BWP">BWP</option>
                <option value="BYN">BYN</option>
                <option value="BZD">BZD</option>
                <option value="CDF">CDF</option>
                <option value="CHF">CHF</option>
                <option value="CLP">CLP</option>
                <option value="CNY">CNY</option>
                <option value="COP">COP</option>
                <option value="CRC">CRC</option>
                <option value="CVE">CVE</option>
                <option value="CZK">CZK</option>
                <option value="DJF">DJF</option>
                <option value="DKK">DKK</option>
                <option value="DOP">DOP</option>
                <option value="DZD">DZD</option>
                <option value="CVE">CVE</option>
                <option value="EEK">EEK</option>
                <option value="EGP">EGP</option>
                <option value="ERN">ERN</option>
                <option value="ETB">ETB</option>
                <option value="GBP">GBP</option>
                <option value="GEL">GEL</option>
                <option value="GHS">GHS</option>
                <option value="GNF">GNF</option>
                <option value="GTQ">GTQ</option>
                <option value="HKD">HKD</option>
                <option value="HNL">HNL</option>
                <option value="HRK">HRK</option>
                <option value="HUF">HUF</option>
                <option value="IDR">IDR</option>
                <option value="ILS">ILS</option>
                <option value="INR">INR</option>
                <option value="IQD">IQD</option>
                <option value="IRR">IRR</option>
                <option value="ISK">ISK</option>
                <option value="JMD">JMD</option>
                <option value="JOD">JOD</option>
                <option value="JPY">JPY</option>
                <option value="KES">KES</option>
                <option value="KHR">KHR</option>
                <option value="KMF">KMF</option>
                <option value="KRW">KRW</option>
                <option value="KWD">KWD</option>
                <option value="KZT">KZT</option>
                <option value="LBP">LBP</option>
                <option value="LKR">LKR</option>
                <option value="LTL">LTL</option>
                <option value="LVL">LVL</option>
                <option value="LYD">LYD</option>
                <option value="MAD">MAD</option>
                <option value="MDL">MDL</option>
                <option value="MGA">MGA</option>
                <option value="MKD">MKD</option>
                <option value="MMK">MMK</option>
                <option value="MOP">MOP</option>
                <option value="MUR">MUR</option>
                <option value="MXN">MXN</option>
                <option value="MYR">MYR</option>
                <option value="MZN">MZN</option>
                <option value="NAD">NAD</option>
                <option value="NGN">NGN</option>
                <option value="NIO">NIO</option>
                <option value="NOK">NOK</option>
                <option value="NPR">NPR</option>
                <option value="NZD">NZD</option>
                <option value="OMR">OMR</option>
                <option value="PAB">PAB</option>
                <option value="PEN">PEN</option>
                <option value="PHP">PHP</option>
                <option value="PKR">PKR</option>
                <option value="PLN">PLN</option>
                <option value="PYG">PYG</option>
                <option value="QAR">QAR</option>
                <option value="RON">RON</option>
                <option value="RSD">RSD</option>
                <option value="RUB">RUB</option>
                <option value="RWF">RWF</option>
                <option value="SAR">SAR</option>
                <option value="SDG">SDG</option>
                <option value="SEK">SEK</option>
                <option value="SGD">SGD</option>
                <option value="SOS">SOS</option>
                <option value="SYP">SYP</option>
                <option value="THB">THB</option>
                <option value="TND">TND</option>
                <option value="TOP">TOP</option>
                <option value="TRY">TRY</option>
                <option value="TTD">TTD</option>
                <option value="TWD">TWD</option>
                <option value="TZS">TZS</option>
                <option value="UAH">UAH</option>
                <option value="UGX">UGX</option>
                <option value="UYU">UYU</option>
                <option value="UZS">UZS</option>
                <option value="VEF">VEF</option>
                <option value="VND">VND</option>
                <option value="XAF">XAF</option>
                <option value="XOF">XOF</option>
                <option value="YER">YER</option>
                <option value="ZAR">ZAR</option>
                <option value="ZMK">ZMK</option>
                <option value="ZWL">ZWL</option>
              </select>
            )}
            <select
              name="method"
              id="method"
              value={selectedPaymentMethod}
              onChange={(e) => setSelectedPaymentMethod(e.target.value)}
            >
              <option value="nowpayment">Nowpayment</option>
              <option value="paystack">Paystack</option>
              <option value="Debit Card">Debit Card</option>
              <option value="ozchestGiftCard">Ozchest Gift Card</option>
              <option value="PaymentWall">Payment Wall</option>
            </select>
          </div>

          {selectedPaymentMethod === "paystack" ? (
            <Paystack buyer={buyer} email={email} amount={Number(amount)} />
          ) : (
            <>
              <div className="mt-3">
                {/* Checkbox for accepting TOS */}
                <input
                  type="checkbox"
                  id="tos"
                  checked={acceptedTOS}
                  onChange={handleTOSChange}
                />{" "}
                <label htmlFor="tos" className="ml-2">
                  I accept the Terms of Service
                </label>
              </div>

              {/* Refill Button, disabled if TOS is not accepted */}
              <button
                className="refill-btn2 mt-4"
                onClick={() => {
                  if (selectedPaymentMethod === "ozchestGiftCard") {
                    handleOzchestGiftCard();
                  } else if (selectedPaymentMethod === "PaymentWall") {
                    handlePaymentWall();
                  } else {
                    handleRefill();
                  }
                }}
                disabled={!acceptedTOS}
              >
                Refill
              </button>
            </>
          )}
        </div>
      </div>
      <div className="payment-div4" id="payment-div4">
        <h5>Create Promo Code</h5>
        <input
          className="email-input1 mt-2"
          placeholder="Code"
          id="code"
        ></input>
        <input
          className="email-input1 mt-3"
          placeholder="Discount%"
          id="discount"
        ></input>

        <div className="btn-div">
          <button
            id="promo-btn"
            className=" mt-3 mb-2 refill-btn"
            onClick={handlePromo}
          >
            Create
          </button>
        </div>
        <div className="btn-div">
          <button
            id="signout2"
            className=" mt-2 mb-2 refill-btn"
            onClick={handleSignOut}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentScreen;
