import { React, useState, useEffect, useRef} from "react";
import "./Header.css";
import logo from "../../Assets/Logo.PNG";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/fontawesome-free-solid";
import PaymentScreen from "../Payment/Payment";
import axios from "axios";
import { useHireContext } from '../../App.js';
const currencyOptions = require("./currencyOptions.json");

function Header(params) {
  const [cartItems, setCartItems] = useState([]);
  //const [cartItems, setCartItems] = useState(
    //   localStorage.getItem("items") ? JSON.parse(localStorage.getItem("items")) : []
    // );
  const {
    currentCurrency,
    setCurrentCurrency
   } = useHireContext();
  const [isPaymentScreenOpen, setIsPaymentScreenOpen] = useState(false);
  const paymentRef = useRef(null);
  
  function convertDecimal(total) {
    let x=(total?.toString()+".0").split(".");
    return parseFloat(x[0]+"."+x[1].substr(0,2)); 
  }

  const [balance, setBalance] = useState(
    sessionStorage.getItem("token")
      ? sessionStorage.getItem("balance")
      : '0:00'
  );

  useEffect(() => {
    const data1 = {
      user: sessionStorage.getItem("email"),
    };

    if (data1.user !== null) {
      axios.post("https://ozchest.com/balance", data1).then(async (data1) => {
        setBalance(data1.data.balance);
        sessionStorage.setItem('balance', data1.data.balance);
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('storage', handleStorage)
    return () => window.removeEventListener('storage', handleStorage)
  }, [])

  const handleStorage = (e) => {
    //setCartItems(JSON.parse(localStorage.getItem('items')));
  }

  useEffect(() => {
    setBalance(sessionStorage.getItem("balance"));
  }, [sessionStorage.getItem("balance")]);

  useEffect(() => {
    // Add event listener to detect clicks outside the popup
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (event?.target?.id === 'money' || event?.target?.id === 'addmoney' || event?.currentTarget?.id === 'paymentScreenButton') {
      return;
    }
    if (paymentRef.current && !paymentRef.current.contains(event.target)) {
      document.getElementById("payment")?.style?.setProperty("display", "none");
      setIsPaymentScreenOpen(false);
    }
  };
  
  function openCartScreen() {
    document.getElementById("cart").style.setProperty("width", "60%");
    document.getElementById("dispatch").style.setProperty("display", "block");
    document.getElementById("cart").classList.add("cart-size");
  }
  function handleChange() {
    const curr = document.getElementById("currentcurrency").value;
    setCurrentCurrency(curr);
    const data2 = {
      from: currentCurrency,
      to: curr,
      amount: balance,
      value: 6,
    };
    axios.post("https://ozchest.com/convert ", data2).then((response2) => {
      setBalance(response2.data.cur)
      sessionStorage.setItem("balance", response2.data.cur);
    });
  }

  function openPayment(e) {
    if (e?.currentTarget?.id !== 'paymentScreenButton') {
      return;
    }
    if (isPaymentScreenOpen) {
      document.getElementById("payment")?.style?.setProperty("display", "none");
    } else {
      document.getElementById("payment")?.style?.setProperty("display", "flex");
    }
    setIsPaymentScreenOpen(!isPaymentScreenOpen);
  }
  const [darkMode, setDarkMode] = useState(params.Mode);
  useEffect(() => {
    if (params.Mode !== darkMode)
    setDarkMode(params.Mode);
  },[params.Mode]);
  return (
    <div
      className={darkMode ? "dark-header-container" : "header-container"}
      id="header-container"
    >
      <div className="header-box">
        <div className="header-logo">
          <img src={logo} width="100" height="30"></img>
        </div>
        <div className="header-options">
          <div
            className={
              darkMode
                ? "header-money dark-header-money mr-4"
                : "header-money mr-4"
            }
            id="header-money"
          >
            <a onClick={openPayment} id="paymentScreenButton">
              <span id="walletIcon"><FontAwesomeIcon className="wallet-icon" icon={faWallet} /></span>
              <span id="money"> {balance ? convertDecimal(balance) : 0}</span>
              <span id="addmoney">+</span>
            </a>
          </div>
          <div ref={paymentRef}>
            `<PaymentScreen balance={balance} />
          </div>
          <div className="header-currency mr-4">
            <select
              className={darkMode ? "currency-btn dark-currency-btn" : "currency-btn"}
              onChange={handleChange}
              name="category"
              id="currentcurrency"
            >
              {currencyOptions.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
          <div className="header-cart">
            <button
              className={darkMode ? "cart-btn dark-cart-btn" : "cart-btn"}
              onClick={openCartScreen}
            >
              <div>
              <i className="cart-icon fa fa-shopping-cart mr-2"></i>
              {cartItems?.length > 0 && <div className="green-dot"></div>}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
